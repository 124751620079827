.translation-example {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  /* top: -200px; */
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}
p.about {
  margin: 10px;
}
@media (max-width: 1024px) {
  .translation-example {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    top: 0;
    flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  }
}
.left-section, .right-section {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}

.left-section .image {
  max-width: 100%;
  height: auto;
}

.right-section {
  width: 50%;
  text-align: start;
}

h1, h2, p {
  margin: 10px 0;
}

h1.about {
  color: #b0884a;
}

h2.about {
  color: #595d61;
}

button.about {
  margin-top: 70px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #b0884a;
  border: none;
  border-radius: 50px;
  width: 30%;
}
button.about:hover {
  margin-top: 70px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #172F4E;
  border: none;
  border-radius: 50px;
  width: 30%;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .right-section {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .translation-example {
    flex-direction: column-reverse; /* Ensure image is on top for mobile view */
    align-items: center;
  }

  .right-section {
    width: 100%;
    text-align: center;
  }

  button.about {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .right-section {
    width: 100%;
  }

  h1.about, h2.about, p {
    font-size: 16px;
  }

  button.about {
    width: 100%;
  }
}
/* About.css */
.translation-example {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}

.right-section {
  flex: 1;
  /* Add your styles for the right section here */
}

.left-section {
  flex: 1;
  position: relative;
}

.image-container {
  perspective: 1000px; /* Add depth for 3D effects */
}

.image {
  width: 100%;
  height: auto;
  transform: scale(1);
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  opacity: 0;
  animation: fadeInUp 1s forwards;
}

@keyframes fadeInUp {
  from {
    transform: translateY(50px) scale(0.9);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.left-section:hover .image {
  transform: scale(1.05); /* Slight zoom effect on hover */
}
