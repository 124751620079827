.translation-example-whyus {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    
    flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  }
  p.about {
    margin: 10px;
  }
  @media (max-width: 1024px) {
    .translation-example-whyus {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      position: relative;
      top: 0;
      flex-wrap: wrap; /* Ensure items wrap on smaller screens */
    }
  }
  .left-section, .right-section {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
  }
  
  .left-section .image {
    max-width: 100%;
    height: auto;
  }
  
  .right-section {
    width: 50%;
    text-align: start;
  }
  
  h1, h2, p {
    margin: 10px 0;
  }
  
  h1.about {
    color: #b0884a;
  }
  
  h2.about {
    color: #595d61;
  }
  
  button.about {
    margin-top: 70px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #b0884a;
    border: none;
    border-radius: 50px;
    width: 30%;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .right-section {
      width: 70%;
    }
  }
  
  @media (max-width: 768px) {
    .translation-example-whyus {
      flex-direction: column-reverse; /* Ensure image is on top for mobile view */
      align-items: center;
    }
  
    .right-section {
      width: 100%;
      text-align: center;
    }
  
    button.about {
      width: 50%;
    }
  }
  
  @media (max-width: 480px) {
    .right-section {
      width: 100%;
    }
  
    h1.about, h2.about, p {
      font-size: 16px;
    }
  
    button.about {
      width: 100%;
    }
  }
  