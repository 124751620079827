.three-boxes {
    display: flex;
    justify-content: space-around;
    margin-right: 120px;
    margin-left: 120px;
    position: relative;
    top: -300px;
  }
  @media (max-width: 1024px) {
    .three-boxes {
      display: flex;
      justify-content: space-around;
      margin-right: 120px;
      margin-left: 120px;
      position: relative;
      top: 0;
    }
  }
  
  .box {
    background-color: #172F4E;
    border: 1px solid #ffffff;
    padding: 20px;
    text-align: center;
    color: white;
    width: 30%;
    box-sizing: border-box;
  }
  
  .icon {
    font-size: 50px;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 24px;
    margin: 10px 0;
    
  }
    
  h2.title {
   color: white;
    
  }
  
  .subtitle {
    font-size: 16px;
    margin: 0;
  }
  @media (max-width: 1024px) {
    .three-boxes {
      display: flex;
      justify-content: space-around;
      margin-right: 120px;
      margin-left: 120px;
      position: relative;
      top: 0;
    }
  }