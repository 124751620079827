/* src/components/ServicePages/ServicePages.css */

.service-page {
  padding: 2rem;
  background-color: #f9f9f9;
}

.service-page h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
  font-size: 2.5rem;
  font-weight: bold;
}

.service-page p {
  text-align: center;
  margin-bottom: 2rem;
  color: #666;
  font-size: 1.25rem;
}

.service-icons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.service-icon {
  font-size: 3rem;
  color: #b39800;
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.product-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 250px;
  justify-content: space-between;

}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.product-card img {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}

.product-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.product-card p {
  font-size: 1rem;
  color: #666;
}
