.faq-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  position: relative;
  /* margin-top: -500px; */
}

.faq-left,
.faq-right {
  flex: 1;
  img {
    width: 100%;
    height: auto;
  }
}

.faq-middle {
  background-color: white;
  flex: 2;
  text-align: center;
  padding: auto;
  border-radius: 50px;
}

button.faq-toggle {
  color: #00324f;
  background-color: white;
  border: solid;
  border-width: 0.5px;
  border-color: gainsboro;
}
button.faq-toggle:hover {
  color: #00324f;
  background-color: white;
  border: solid;
  border-width: 0.5px;
  border-color: gainsboro;
}

.faq-title {
  font-size: 24px;
  .faq-underline {
    width: 50%;
    margin: 0 auto;
    border: 1px solid #000;
  }
}

.faq-table {
  width: 100%;
}

.faq-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.faq-question {
  color: #00324f;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.faq-toggle {
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.faq-answer {
  margin-left: 34px;
  font-size: 16px;
  padding-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .faq-middle {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .faq-container {
    flex-direction: column;
    align-items: center;
    position: relative;
        /* margin-top: -300px; */
  }

  .faq-middle {
    width: 100%;
    text-align: center;
  }

  button.faq-toggle {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .faq-title, .faq-subtitle {
    font-size: 18px;
  }

  button.faq-toggle {
    font-size: 20px;
  }

  .faq-answer {
    font-size: 14px;
  }
}
