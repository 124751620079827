.contact-page {
  padding: 2rem;
  max-width: 100%;
  margin: -500px 100px 100px;
  background-color: #f9f9f9;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 
  border-radius: 50px;
}
@media (max-width: 1024px) {
  .contact-page {
    padding: 2rem;
    max-width: 600px;
    margin: 10px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: -300px;
  }
}
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  .submit-button {
    background-color: #A68852;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #A68852;
  }
  