/* src/components/ServicePages/ElectronicServices.css */

.electronic-services {
  padding: 2rem;
  background-color: #ffffff; /* خلفية بيضاء */
  color: #240c5c; /* لون النص */
}

.electronic-services h1,
.electronic-services h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.electronic-services p {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.intro-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.intro-section .intro-image {
  width: 50%;
  border-radius: 8px;
}

.intro-section p {
  width: 45%;
  font-size: 1.1rem;
}

.category-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.category-button {
  background-color: #240c5c;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: 'Cairo', sans-serif; /* استخدام نفس الخط */
  font-size: 1rem;
}

.category-button:hover,
.category-button.active {
  background-color: rgb(197, 139, 14);
  transform: scale(1.05);
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.product-item {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
  width: 180px; /* تصغير الكارد */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #240c5c;
}

.product-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.product-image {
  width: 80px; /* تصغير الصورة */
  height: 80px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.product-item h3 {
  font-size: 1rem; /* تصغير النص */
  margin-bottom: 0.5rem;
}

.product-item p {
  font-size: 0.8rem; /* تصغير النص */
  margin-bottom: 0.5rem;
}

.request-button {
  background-color: #A68852;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: 'Cairo', sans-serif; /* استخدام نفس الخط */
  font-size: 1rem;
}

.request-button:hover {
  background-color: rgb(197, 139, 14);
  transform: scale(1.05);
}

.service-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  margin: 2rem auto;
  outline: none;
}

.service-modal h2 {
  margin-top: 0;
}

.service-details {
  width: 90%;
  margin-top: -600px;
  border-collapse: collapse;
  position: relative;
}
@media (max-width: 1024px) {
  .service-details {
    width: 80%;
    margin-top: -300px;
    border-collapse: collapse;
    position: relative;
  }
}

.service-details th,
.service-details td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.service-details th {
  background-color: #f1f1f1;
}

.service-details td {
  background-color: #fff;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.modal-buttons button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #240c5c;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.modal-buttons button:hover {
  background-color: rgb(197, 139, 14);
  transform: scale(1.05);
}

.service-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rtl {
  font-family: 'Cairo', sans-serif;
  direction: rtl;
  text-align: right;
}

.ltr {
  font-family: 'Roboto', sans-serif;
  direction: ltr;
  text-align: left;
}
