
.slick-slider {
  background-color: black !important; }
.hero {
  position: relative;
  overflow: hidden; 
}

.hero-slide {
  position: relative;
}

.hero-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  animation: zoomOut 13s ease-out infinite;
}

.hero-text {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.slick-dots {
  display: flex !important;
  flex-direction: column;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.slick-dots li {
  margin: 0 !important;
  list-style-type: none;
}

.slick-dots li button {
  font-size: 0 !important;
  border: none;
  background: transparent;
  cursor: pointer;
}

.slick-dots li button div {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
}

.slick-dots li.slick-active button div {
  background-color: #000 !important;
}


.home {
 
  background-color: #ffffff;
  color: #333;
}

.hero {
  position: relative;
  text-align: center;
  color: white;
  margin-bottom: 2rem;

  overflow: hidden;

}
@media (max-width: 1024px) {
  .hero {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 2rem;
  
    overflow: hidden;
    top: 0px;
  }
}

.hero-image {
  width: 100%;
  height: auto;
  opacity: 0.8;
  border-radius: 15px;
}

.hero-text {
  position: absolute;
  top: 50%;
  right: -10%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgb(0 0 0 / 0%);
  padding: 1rem 2rem;
  border-radius: 10px;
}

.hero-text h1 {
  font-size: 4rem;
  margin: 0;
  color: #fff;

}

.hero-text p {
  font-size: 1.5rem;
  color: #ddd;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  margin-top: 1rem;
}
h3.services-title {
  color: #b0884a;
}
.services-offered {
  /* margin-top: 50px; */
  text-align: center;
  position: relative;
  top: -50px;
  padding-right: 30px;
    padding-left: 20px;
}

.services-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  justify-content: center;
}
@media (max-width: 1024px) {
  .services-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    justify-content: center;
  }
  .services-offered {
    margin-top: 50px;
    text-align: center;
    position: relative;
    top: 0;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.service-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.service-card .request-button {
  color: white;
  text-decoration: none;
  background-color: #b0884a;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: auto;
}

.service-card .request-button:hover {
  background-color: #172F4E;
  transform: scale(1.05);
}

.most-requested {
  margin-top: 50px;
  text-align: center;
}

.products-carousel {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.product-card {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 180px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  justify-content: space-between;

}

.product-card.active {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-card img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.product-card .request-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
}

.product-card .request-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.logo-carousel {
  margin-top: 50px;
  text-align: center;
  overflow: hidden;
}

.logos {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: nowrap;
  animation: scroll 10s linear infinite;
}

.logos img {
  height: 100px;
  transition: transform 0.3s ease;
}

.logos img:hover {
  transform: scale(1.1);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}