/* src/components/Products.css */

.products {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: -500px 100px 100px;
  background-color: var(--background-color);
  color: var(--heading-color);
  position: relative;

  border-radius: 50px;
}
.categories-slider {
  display: flex;
  overflow-x: auto;
  padding: 1rem 0;
  gap: 1rem;
  margin-bottom: 2rem;
}

.category-item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  padding: 0.5rem 1rem;
  border-radius: var(--card-border-radius);
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.category-item:hover,
.category-item.active {
  background-color: #172F4E;
  color: white;
  transform: scale(1.05);
}

.category-image {
  width: 40px; /* تصغير حجم الصورة */
  height: 40px; /* تصغير حجم الصورة */
  object-fit: contain; /* تعديل fit الصورة */
  margin-bottom: 0.5rem;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.product-card {
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  text-align: center;
  width: 180px;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--heading-color);
  justify-content: space-between;

}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--hover-shadow);
}

.product-image {
  width: 60px; /* تصغير الصورة */
  height: 60px;
  object-fit: contain; /* تعديل fit الصورة */
  margin-bottom: 1rem;
}

.product-card h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.product-card p {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.button {
  background-color: #A68852;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  /* transition: background-color var(--transition-duration), transform var(--transition-duration); */
}

.button:hover {
  background-color: rgb(197, 139, 14);
  transform: scale(1.05);
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: var(--heading-color);
}
