/* src/components/ServicePages/ConsultationServices.css */

.service-page {
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--heading-color);
}

.service-page h1,
.service-page h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: var(--font-family-secondary);
}

.service-page p {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-family: var(--font-family-secondary);
}

.intro-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.intro-section .intro-image {
  width: 50%;
  border-radius: var(--card-border-radius);
}

.intro-section p {
  width: 45%;
  font-size: 1.1rem;
  text-align: left;
  font-family: var(--font-family-secondary);
}

.service-icons {
  text-align: center;
  margin-bottom: 2rem;
}

.service-icon {
  font-size: 3rem;
  color: #00324f;
  margin: 0 1rem;
}

.services-list {
  text-align: center;
  margin: 2rem 0;
}

.services-list ul {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
  font-family: var(--font-family-secondary);
}

.services-list li {
  margin-bottom: 0.5rem;
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.product-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  text-align: center;
  width: 180px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--heading-color);
  justify-content: space-between;

}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--hover-shadow);
}

.product-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.product-card h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.product-card p {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.request-button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: var(--font-family-secondary);
  font-size: 1rem;
}

.request-button:hover {
  background-color: #172F4E;
  transform: scale(1.05);
}
