
.subscriptions {
  padding: 2rem;
  background-color: var(--light-background);
  border-radius: 60px;
  margin: 100px;
  position: relative;
  margin-top: -500px;
}

.subscriptions-title {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--secondary-color); /* Ø§Ø³ØªØ®Ø¯Ù… Ù…ØªØºÙŠØ± Ø§Ù„Ù„ÙˆÙ† Ø§Ù„Ø«Ø§Ù†ÙˆÙŠ */
  font-size: 2.5rem;
  font-weight: bold;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.tab-button {
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 0.75rem 1.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color var(--transition-duration), color var(--transition-duration);
}

.tab-button.active {
  background-color: var(--primary-color);
  color: white;
}

.packages-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.package {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  margin-bottom: 2rem;
  position: relative;
  background-color: var(--background-color);
  border-radius: var(--card-border-radius);
  overflow: hidden;
  box-shadow: var(--card-shadow);
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.package:hover {
  transform: translateY(-10px);
  box-shadow: var(--hover-shadow);
}

.package::before,
.package::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--card-hover-background);
  transition: width 0.5s;
}

.package::before {
  top: 0;
  left: 0;
}

.package::after {
  bottom: 0;
  right: 0;
}

.package:hover::before {
  width: 100%;
}

.package:hover::after {
  width: 100%;
}

.package-header {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem;
  background: var(--card-hover-background);
  color: white;
  border-radius: 8px 8px 0 0;
}

.sahab-header {
  background: linear-gradient(45deg, #667eea, #764ba2); /* Adjust the colors */
}

.legal-header {
  background: linear-gradient(45deg, #00c6ff, #0072ff); /* Adjust the colors */
}

.wage-protection-header {
  background: linear-gradient(45deg, #f857a6, #ff5858); /* Adjust the colors */
}

.package-description {
  text-align: center;
  margin: 1rem 0;
  padding: 0 1rem;
  font-size: 1rem;
  color: var(--text-color); /* Ø§Ø³ØªØ®Ø¯Ù… Ù…ØªØºÙŠØ± Ù„ÙˆÙ† Ø§Ù„Ù†Øµ */
}

.package-content {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.subscriptions-table {
  width: 100%;
  border-collapse: collapse;
}

.subscriptions-table td {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  text-align: right;
  font-family: var(--font-family-secondary);
}

.subscriptions-table tr:nth-child(odd) {
  background-color: #f1f1f1;
}

.price-row {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  background-color: var(--light-background);
}

.original-price {
  color: var(--primary-color);
}

.discount {
  color: #28a745;
}

.final-price {
  color: #dc3545;
}

.subscribe-button-container {
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
}

.subscribe-button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration);
}

.subscribe-button:hover {
  background-color: var(--primary-hover-color);
  transform: scale(1.05);
  box-shadow: var(--hover-shadow);
}

.note-row {
  text-align: center;
  font-size: 1rem;
  color: var(--text-color);
  margin-top: 0.5rem;
}

.service-details {
  list-style: none;
  padding: 0;
  margin: 0;
}

.service-details li {
  padding: 0.5rem 0;
}

.toggle-icon {
  text-align: center;
  cursor: pointer;
}