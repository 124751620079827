 /* src/components/LaborCalculator.css */
.labor-calculator {
  padding: 2rem;
    background-color: #f9f9f9;
    position: relative;
    margin: 100px;
    font-family: 'Cairo', sans-serif;
    margin-top: -500px;
    border-radius: 50px;
}
@media (max-width: 1024px) {
  .labor-calculator {
    padding: 2rem;
      background-color: #f9f9f9;
      position: relative;
      margin: 10px;
      font-family: 'Cairo', sans-serif;
      margin-top: -300px;
  }
}

.calculator-section {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

h1, h2 {
  text-align: center;
  color: #333;
}

label {
  display: block;
  margin: 0.5rem 0;
  color: #333;
}

input[type="number"] {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
  font-family: 'Cairo', sans-serif; /* تطبيق نفس الخط المستخدم */
}

button:hover {
  background-color: #0056b3;
}

.result {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #e9ecef;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copy-icon {
  cursor: pointer;
  color: #007bff;
  margin-left: 0.5rem;
  transition: color 0.3s ease;
}

.copy-icon:hover {
  color: #0056b3;
}

.total-salary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
}
