/* src/components/ServicePages/EstablishmentServices.css */

.service-page {
    padding: 2rem;
    background-color: #ffffff;
    color: #240c5c;
    position: relative;
    margin: 100px;
    border-radius: 60px;
    margin-top: -600px;
  }
  @media (max-width: 1024px) {
    .service-page {
      padding: 2rem;
      background-color: #ffffff;
      color: #240c5c;
      position: relative;
      margin: 10px;
      border-radius: 60px;
      margin-top: -300px;
    }
  }
  .service-page h1,
  .service-page h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: 'Cairo', sans-serif;
  }
  
  .service-page p {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-family: 'Cairo', sans-serif;
  }
  
  .intro-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .intro-section .intro-image {
    width: 50%;
    border-radius: 8px;
  }
  
  .intro-section p {
    width: 45%;
    font-size: 1.1rem;
    text-align: left;
    font-family: 'Cairo', sans-serif;
  }
  
  .service-icons {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .service-icon {
    font-size: 3rem;
    color: #00324f;
  }
  
  .steps {
    text-align: center;
    margin: 2rem 0;
  }
  
  .steps h2 {
    font-family: 'Cairo', sans-serif;
  }
  
  .steps-list {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  
  .step {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 200px;
    text-align: center;
    font-family: 'Cairo', sans-serif;
  }
  
  .step-icon {
    font-size: 2rem;
    color: #240c5c;
    margin-bottom: 0.5rem;
  }
  
  .products-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  
  .product-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    width: 180px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #240c5c;
    justify-content: space-between;

  }
  
  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .product-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .product-card h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .product-card p {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
  
  .request-button {
    background-color: #A68852;
    color: white; /* تعديل لون النص إلى الأبيض */
    padding: 0.5rem 1rem;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-family: 'Cairo', sans-serif;
    font-size: 1rem;
  }
  
  .request-button:hover {
    background-color: rgb(197, 139, 14);
    transform: scale(1.05);
  }
  