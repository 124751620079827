/* src/components/ServiceDetails.css */

.service-details {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: var(--background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  text-align: center;
}

.service-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.service-table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

.service-table th,
.service-table td {
  padding: 0.5rem;
  border: 1px solid #ddd;
}

.button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  /* transition: background-color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration); */
}

.button:hover {
  background-color: #172F4E;
  transform: scale(1.05);
  box-shadow: var(--hover-shadow);
}
/* .service-details {
  padding: 2rem;
  background-color: #f9f9f9;
}

.service-details h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.service-item {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.service-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  margin: 2rem auto;
  outline: none;
}

.service-modal h2 {
  margin-top: 0;
}

.service-modal .service-details {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
}

.service-modal .service-details th,
.service-modal .service-details td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.service-modal .service-details th {
  background-color: #f1f1f1;
}

.service-modal .service-details td {
  background-color: #fff;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.modal-buttons button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-family: 'Roboto', sans-serif; 
}

.modal-buttons button:hover {
  background-color: #0056b3;
}

.service-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rtl {
  font-family: 'Cairo', sans-serif;
  direction: rtl;
  text-align: right;
}

.ltr {
  font-family: 'Roboto', sans-serif;
  direction: ltr;
  text-align: left;
} */
