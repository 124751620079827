.footer {
  background-color: #ffffff;
  padding: 40px 20px;
}
img.callIcon {
  width: 20%;
  position: relative;
  top:10px;
}
.footerImage {
  display: flex;
  flex-direction: column;
  /* padding: 50px; */
}
.top-section {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  flex-direction: row;
  padding: 20px;
  text-align: center;
  margin-bottom: 40px;
  background-color: #172F4E;
  border-radius: 50px 50px 0;
}
.third-section {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  flex-direction: row;
  padding: 20px;
  text-align: center;
  margin-bottom: 40px;
  background-color: #ffffff;
}
.footer-title {
  font-size: 24px;
  margin-bottom: 10px;
  color:white;
}

.footer-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #b0884a;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100%;
 
}
.footer2-button{
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #b0884a;
  color: white;
  border: none;
  border-radius: 5px;
  width: fit-content;
  position: relative;
    right: 50px;
}
.footer2-button:hover{
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #172F4E;
  color: white;
  border: none;
  border-radius: 5px;
  width: fit-content;
}
@media (max-width: 1024px) {
  .footer-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #b0884a;
    color: white;
    border: none;
    border-radius: 5px;
    width: 100%;
    position: relative;
    right: 5px;
  }
}
.footer-button:hover {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #172F4E;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100%;
 
}
.form-button:hover{
  background-color: #172F4E;
}
.bottom-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #172f4e;
  border-radius: 50px 50px 0px;
}

.footer-section {
  text-align: center;
  flex: 1;
  min-width: 200px;
  margin: 20px 0;
  padding: 10px;
}

.footer-logo {
  width: 50%;
  margin-bottom: 10px;
  text-align: right;
}

.footer-paragraph {
  color: #6c757d;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 5px;
}

.footer-links a {
  color: #b0884a;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-social-links a {
  display: block;
  color: #b0884a;
  margin-bottom: 5px;
  text-decoration: none;
}

.footer-social-links a:hover {
  text-decoration: underline;
}

footer form {
  display: flex;
  flex-direction: column;
}

footer input,
footer textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

footer button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #b0884a;
  color: white;
  border: none;
  border-radius: 5px;
}

.social-icons {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.social-icons li {
  margin: 0 10px;
}

.social-icons a {
  text-decoration: none;
  color: #000;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #172F4E; /* Adjust hover color */
}
