.stats-container {
    text-align: center;
    padding: 40px;
    border-radius: 50px;
  
    .stats-title {
      font-size: 52px;
      margin-bottom: 20px;
    }
    @media (max-width: 1024px) {
      .stats-title {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
    .stats-count {
        color: #b0884a;
    }
    .stats-blocks {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
  
      .stats-block {
        flex: 1 1 200px;
        margin: 10px;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #f9f9f9;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
        .stats-count {
          font-size: 54px;
          font-weight: bold;
          margin-bottom: 10px;
        }
  
        .stats-description {
          font-size: 18px;
          color: #555;
        }
      }
    }
  }
  