/* src/index.css */

:root {
  /* ألوان */
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --secondary-color: #333;
  --background-color: white;
  --text-color: #666;
  --card-background-color: rgba(255, 255, 255, 0.9);
  --card-hover-background: linear-gradient(90deg, rgba(36, 12, 92, 1) 0%, rgb(197, 139, 14) 100%);
  --card-border-radius: 12px;
  --card-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --hover-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  --heading-color: #240c5c;
  --light-background: #f2f2f2;
  --list-item-background: #f7f7f7;
  --icon-color: #240c5c;
  --icon-hover-transform: rotate(360deg);

  /* حركات وانتقالات */
  --transition-duration: 0.3s;
  --transform-scale: scale(1.05);

  /* خطوط */
  --font-family-primary: 'Roboto', sans-serif;
  --font-family-secondary: 'Cairo', sans-serif;
}

body {
  font-family: var(--font-family-primary);
  display: block;
  margin: 0 !important;
}

.rtl {
  font-family: var(--font-family-secondary);
  direction: rtl;
  text-align: right;
}

.ltr {
  font-family: var(--font-family-primary);
  direction: ltr;
  text-align: left;
}

