
/* this the old css */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.8); /* خلفية شبه شفافة */
  z-index: 1;
  box-shadow: var(--card-shadow);
}

.top-bar-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.company-info {
  display: flex;
  align-items: center;
}

.company-info .logo, .additional-logo {
  width: 70px; /* تكبير الشعار */
  margin-right: 10px;
}

.additional-logo {
  margin-left: 10px; /* لضمان وجود مسافة بين النص والشعار الجديد */
}

.vision-logo .logo {
  width: 90px; /* تكبير الشعار */
}

.company-name {
  color: #b39800; /* اللون المطلوب */
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 10px;
}

.top-bar.rtl {
  direction: rtl;
}

.top-bar.ltr {
  direction: ltr;
}
