
:root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --secondary-color: #333;
  --background-color: white;
  --text-color: #666;
  --card-background-color: rgba(255, 255, 255, 0.9);
  --card-hover-background: linear-gradient(90deg, rgba(36, 12, 92, 1) 0%, rgb(197, 139, 14) 100%);
  --card-border-radius: 12px;
  --card-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --hover-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  --heading-color: #240c5c;
  --light-background: #f2f2f2;
  --list-item-background: #f7f7f7;
  --icon-color: #240c5c;
  --icon-hover-transform: rotate(360deg);
  --transition-duration: 0.3s;
  --transform-scale: scale(1.05);
  --font-family-primary: 'Roboto', sans-serif;
  --font-family-secondary: 'Cairo', sans-serif;
}

.serviceshome {
  
  
    padding: 0px;
    background-color: #f9f9f9;
    color: #333;
    
   
}
/* @media (max-width: 1024px) {
  .services {
    margin: 10px;
    margin-top: -300px;
      padding: 0px;
      background-color: #f9f9f9;
      color: #333;
      position: relative;
     
  }
} */
h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}
.categories-container, .categories-list {
  display: flex;
  justify-content: center;
  width: 70% !important;
  align-content: center !important;
  justify-content: flex-end;
}
.categories-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 70%;
}

.categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 70%;
}

.category-card {
  padding: 20px;
  border-radius: var(--card-border-radius);
  background-color: var(--card-background-color);
  box-shadow: var(--card-shadow);
  cursor: pointer;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  flex: 1 1 calc(33.33% - 20px);
}

.category-card.active {
  background-color: white;
  color: #333;
  z-index: 3;
  width: 100%;
}

.category-card:hover {
  transform: var(--transform-scale);
  box-shadow: var(--hover-shadow);
}

.category-logo-home {
  width: 70%;
  height: 70%;
  margin-bottom: 10px;
}

.category-card p {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.services-dropdown {
  margin-top: 10px;
  padding: 20px;
  background-color: white;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  overflow: hidden;
  width: 100%;
  position: relative;
  border: none;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.service-item {
  padding: 10px;
  background-color: var(--list-item-background);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.service-item h3 {
  font-size: 1rem;
  color: #240c5c;
  margin: 0 0 10px 0;
}

.button {
  background-color: #A68852;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  /* transition: background-color var(--transition-duration), transform var(--transition-duration); */
}

.button:hover {
  background-color: #172F4E;
  transform: var(--transform-scale);
}

/* Overlay to prevent interaction with background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: none;
}

.overlay.active {
  display: block;
}
/* src/components/Services.css

.services {
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.categories-slider {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px 0;
  margin-bottom: 20px;
  scrollbar-width: thin; 
  scrollbar-color: #240c5c #f9f9f9; 
}


.categories-slider::-webkit-scrollbar {
  height: 8px;
}

.categories-slider::-webkit-scrollbar-thumb {
  background-color: #240c5c;
  border-radius: 10px;
}

.categories-slider::-webkit-scrollbar-track {
  background-color: #f9f9f9;
}

.category-item {
  flex: 0 0 auto;
  text-align: center;
  padding: 10px;
  border-radius: var(--card-border-radius);
  background-color: var(--card-background-color);
  box-shadow: var(--card-shadow);
  cursor: pointer;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
  min-width: 120px;
}

.category-item.active {
  background-color: #240c5c;
  color: #fff;
}

.category-item:hover {
  transform: var(--transform-scale);
  box-shadow: var(--hover-shadow);
}

.category-image {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}

.search-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.service-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.service-card {
  background-color: var(--card-background-color);
  padding: 20px;
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  text-align: center;
  cursor: pointer;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.service-card:hover {
  transform: var(--transform-scale);
  box-shadow: var(--hover-shadow);
}

.service-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

h3 {
  font-size: 1.2rem;
  color: #240c5c;
  margin-bottom: 10px;
}

.button {
  background-color: #240c5c;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color var(--transition-duration), transform var(--transition-duration);
  display: inline-block;
}

.button:hover {
  background-color: #1a0840;
  transform: var(--transform-scale);
} */
