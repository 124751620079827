.company-location {
    text-align: center;
    padding: 20px;
  }
  
  .location-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .location-subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  