:root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --secondary-color: #333;
  --background-color: white;
  --text-color: #666;
  --card-background-color: rgba(255, 255, 255, 0.9);
  --card-hover-background: linear-gradient(90deg, rgba(36, 12, 92, 1) 0%, rgb(197, 139, 14) 100%);
  --card-border-radius: 12px;
  --card-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --hover-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  --heading-color: #240c5c;
  --light-background: #f2f2f2;
  --list-item-background: #f7f7f7;
  --icon-color: #240c5c;
  --icon-hover-transform: rotate(360deg);
  --transition-duration: 0.3s;
  --transform-scale: scale(1.05);
  --font-family-primary: 'Roboto', sans-serif;
  --font-family-secondary: 'Cairo', sans-serif;
}
img.langIcon {
  width: 10%;
}
img.header-logo {
  width: 60%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background: #172F4E;
  box-shadow: var(--card-shadow);
  position: sticky;
  top: 0;
  z-index: 1000;
  /* border-radius: var(--card-border-radius); */
  margin: 0;
}

.header .header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.header .menu-toggle {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.header .nav {
  display: flex;
}

.header .nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.header .nav ul li {
  margin: 0;
}

.header .nav ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  transition: color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration);
}

.header .nav ul li a:hover {
  /* color: var(--primary-hover-color); */
  text-decoration: underline;
  transform: scale(3.1);
  text-underline-offset: 3px; /* Adjust the value as needed */
  /* text-shadow: 0 0 10px var(--primary-hover-color); */
  border-radius: 8px;
  color: #b0884a;
}

.header2-button{
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #b0884a;
  color: white;
  border: none;
  border-radius: 5px;
  width: max-content;
 
}
.header2-button:hover{
  /* padding: 10px 20px;
  font-size: 16px;
  cursor: pointer; */
  background-color: #172F4E;
  /* color: white;
  border: none;
  border-radius: 5px;
  width: max-content; */
}
.header .language-switcher {
  display: flex;
  align-items: center;
}
.Header-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  justify-content: space-between;
}
.header .language-switcher button {
  display: flex;
  align-items: center;
  margin-right: 10px;
  border: none;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  border: solid;
  border-width: thin;
  width: max-content;
  font-weight: bold;
  transition: background-color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration);

}

.header .language-switcher button:hover {
  /* background-color: var(--primary-hover-color); */
  transform: scale(1.05);
  /* box-shadow: var(--hover-shadow); */
}

.header .language-switcher button .icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.header.rtl {
  direction: rtl;
}

.header.ltr {
  direction: ltr;
}

@media (max-width: 1024px) {
  .Header-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    justify-content: space-between;
  }
  .header {
    padding: 0.5rem 1rem;
  }

  .header .menu-toggle {
    display: block;
    width: 50%;
  }
  img.header-logo {
    width: 50% !important;
}

  .header .nav {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .header .nav.open {
    display: flex;
  }

  .header .nav ul {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }

  .header .nav ul li a {
    padding: 0.5rem 1rem;
    width: 100%;
    text-align: center;
  }

  .header .language-switcher {
    /* margin-top: 1rem; */
    width: max-content;

    justify-content: center;
  }
}

@media (min-width: 1025px) {
  .header .menu-toggle {
    display: none;
    
  }

  .header .nav {
    display: flex;
  }

  .header .nav ul {
    flex-direction: row;
    gap: 1rem;
  }

  .header .nav ul li a {
    padding: 0;
    text-align: left;
  }

  .header .language-switcher {
    margin-top: 0;
    width: 20%;
    justify-content: flex-start;
  }
}
.has-dropdown {
  position: relative;
  color: white;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  z-index: 1000;
  display: none ; /* Hide the dropdown by default */
}

.has-dropdown:hover .dropdown-menu {
  display: block; /* Show the dropdown when hovering over the "Services" menu item */
}

.dropdown-menu li {
  padding: 8px 16px;
  width: max-content;
}

.dropdown-menu li a {
  color: black;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  background-color: #f1f1f1;
}
.header .nav .has-dropdown .dropdown-menu {
  display: none; /* Ensure dropdown is hidden by default */
}

.header .nav .has-dropdown:hover .dropdown-menu {
  display: block; /* Show dropdown on hover */
}


/* .has-dropdown {
  position: relative;
  color: white;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
 
  z-index: 1000;
  display: block;
}

.dropdown-menu li {
  padding: 8px 16px;
  width: max-content;
}

.dropdown-menu li a {
  color: black;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  background-color: #f1f1f1;
}
ul.dropdown-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
} */
/* :root {
  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --secondary-color: #333;
  --background-color: white;
  --text-color: #666;
  --card-background-color: rgba(255, 255, 255, 0.9);
  --card-hover-background: linear-gradient(90deg, rgba(36, 12, 92, 1) 0%, rgb(197, 139, 14) 100%);
  --card-border-radius: 12px;
  --card-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --hover-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  --heading-color: #240c5c;
  --light-background: #f2f2f2;
  --list-item-background: #f7f7f7;
  --icon-color: #240c5c;
  --icon-hover-transform: rotate(360deg);
  --transition-duration: 0.3s;
  --transform-scale: scale(1.05);
  --font-family-primary: 'Roboto', sans-serif;
  --font-family-secondary: 'Cairo', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
 
  background:#00324f;

  box-shadow: var(--card-shadow);
  position: sticky;
  top: 0;
  z-index: 1000;
  border-radius: var(--card-border-radius);
  margin: 50px;
}

.header .header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.header .language-switcher {
  display: flex;
  align-items: center;
}

.header .language-switcher button {
  display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: none;
    background-color: white;
    color: #00324f;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 0px;
    font-weight: bold;
    transition: background-color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration);
    margin-left: 1rem;
}

.header .language-switcher button:hover {
  background-color: var(--primary-hover-color);
  transform: scale(1.05);
  box-shadow: var(--hover-shadow);
}

.header .language-switcher button .icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.header nav ul li {
  margin: 0;
}

.header nav ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  transition: color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration);
}

.header nav ul li a:hover {
  color: var(--primary-hover-color);
  transform: scale(1.1);
  text-shadow: 0 0 10px var(--primary-hover-color);
  border-radius: 8px;
}

.header.rtl {
  direction: rtl;
}

.header.ltr {
  direction: ltr;
} */
