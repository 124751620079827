.faq-page {
  padding: 2rem;
  max-width: 100%;
  margin: -500px 100px 100px;
  background-color: #f9f9f9;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.faq-content {
  display: flex;
  width: 100%;
}

.faq-section {
  flex: 3;
  padding: 20px;
  background-color: #fff;
  margin-right: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.faq-sidebar {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.faq-sidebar h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.faq-sidebar ul {
  list-style: none;
  padding: 0;
}

.faq-sidebar ul li {
  margin-bottom: 10px;
}

.faq-sidebar ul li a {
  text-decoration: none;
  color: #007bff;
  font-size: 1.1em;
}

.faq-sidebar ul li a:hover {
  text-decoration: underline;
}

.faq-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333;
}

.faq-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  font-size: 1.2em;
  font-weight: bold;
}

.faq-answer {
  padding-top: 10px;
  font-size: 1em;
  color: #555;
}
