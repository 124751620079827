/* src/components/ProductDetails.css */

.product-details {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: var(--background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  text-align: center;
  position: relative;
  margin-top: -500px;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.product-table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

.product-table th,
.product-table td {
  padding: 0.5rem;
  border: 1px solid #ddd;
}

.button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #A68852;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  /* transition: background-color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration); */
}

.button:hover {
  background-color: #172F4E;
  transform: scale(1.05);
  box-shadow: var(--hover-shadow);
}

/* Products.css */
.products {
  display: flex;
}

.sidebar {
  width: 200px;
  padding: 1rem;
  background-color: #f4f4f4;
  border-right: 1px solid #ddd;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 0.5rem 0;
  cursor: pointer;
}

.sidebar ul li.active {
  font-weight: bold;
  color: var(--primary-color);
}

.product-list {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.product-card {
  background-color: var(--background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  text-align: center;
  width: 200px;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: var(--hover-shadow);
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #A68852;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  /* transition: background-color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration); */
}

.button:hover {
  background-color: #172F4E;
  transform: scale(1.05);
  box-shadow: #d79e35;
}
