.three-boxes {
  display: flex;
  justify-content: space-around;
  margin-right: 120px;
  margin-left: 120px;
  position: relative;
  top: -300px;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}
@media (max-width: 1024px) {

  .three-boxes {
    display: flex;
    justify-content: space-around;
    margin-right: 120px;
    margin-left: 120px;
    position: relative;
    top: 0;
    flex-wrap: wrap; /* Ensure items wrap on smaller screens */
  }
}

.box {
  background-color: #0a2f4f;
  border: 1px solid #ffffff;
  padding: 20px;
  text-align: center;
  color: white;
  width: 30%;
  box-sizing: border-box;
  margin-bottom: 20px; /* Add space between rows on smaller screens */
}

.icon {
  font-size: 50px;
  margin-bottom: 10px;
}

.title {
  font-size: 24px;
  margin: 10px 0;
}

h2.title {
  color: white;
}

.subtitle {
  font-size: 16px;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .three-boxes {
    margin-right: 60px;
    margin-left: 60px;
  }

  .box {
    width: 45%; /* Two boxes per row */
  }
}

@media (max-width: 768px) {
  .three-boxes {
    margin-right: 30px;
    margin-left: 30px;
  }

  .box {
    width: 100%; /* One box per row */
  }

  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .three-boxes {
    margin-right: 15px;
    margin-left: 15px;
  }

  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 12px;
  }
}
