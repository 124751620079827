/* src/components/ServicePages/CorrectionServices.css */

.service-page {
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--heading-color);
}

.service-page h1,
.service-page h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: var(--font-family-secondary);
}

.service-page p {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-family: var(--font-family-secondary);
}

.intro-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.intro-section .intro-image {
  width: 50%;
  border-radius: var(--card-border-radius);
}

.intro-section p {
  width: 45%;
  font-size: 1.1rem;
  text-align: left;
  font-family: var(--font-family-secondary);
}

.service-icons {
  text-align: center;
  margin-bottom: 2rem;
}

.service-icon {
  font-size: 3rem;
  color: #00324f;
  margin: 0 1rem;
}

.steps {
  text-align: center;
  margin: 2rem 0;
}

.steps-list {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--card-background);
  padding: 1rem;
  border-radius: var(--card-border-radius);
  width: 200px;
  box-shadow: var(--card-shadow);
}

.step-icon {
  font-size: 2.5rem;
  color: #00324f;
  margin-bottom: 0.5rem;
}

.steps-list h3 {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  color: #00324f;
}

.steps-list p {
  font-size: 1rem;
  color: var(--text-color-secondary);
}

.services-list {
  text-align: center;
  margin: 2rem 0;
}

.services-list ul {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
  font-family: var(--font-family-secondary);
}

.services-list li {
  margin-bottom: 0.5rem;
}

.contact-button {
  display: block;
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: var(--font-family-secondary);
  font-size: 1rem;
  margin: 0 auto;
}

.contact-button:hover {
  background-color: var(--primary-hover-color);
  transform: scale(1.05);
}
