/* Hero section */
.hero {
    position: relative;
    overflow: hidden;
  }
  
  .hero-slide {
    position: relative;
  }
  
  .hero-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    animation: zoomOut 13s ease-out infinite;
   
  }
  
  .hero-text {
    position: absolute;
    right: 20px;
    top: 70%;
    transform: translateY(-50%);
    background: transparent;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  
  .hero-text h1 {
    font-size: 3rem;
    margin: 0;
    color: #fff;
  }
  
  .hero-text p {
    font-size: 1.5rem;
    color: #ddd;
    margin-top: 1rem;
  }
  
  @keyframes zoomOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Slick slider customization */
  .slick-slider {
    background-color: black !important;
  }
  
  .slick-dots {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .slick-dots li {
    margin: 0 !important;
    list-style-type: none;
  }
  
  .slick-dots li button {
    font-size: 0 !important;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  
  .slick-dots li button div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
  }
  
  .slick-dots li.slick-active button div {
    background-color: #000 !important;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .hero-image {
      height: 80vh;
     
    }
  
    .hero-text h1 {
      font-size: 2.5rem;
    }
  
    .hero-text p {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 600px) {
    .hero-image {
      height: 60vh;
     
    }
  
    .hero-text h1 {
      font-size: 2rem;
    }
  
    .hero-text p {
      font-size: 1rem;
    }
  }
  